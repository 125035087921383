<template>
  <div class="save-article-container">
    <p class="save-article-container__title">{{ title }}</p>
    <div class="save-article-container__input-field">
      <a-select
        v-model:value="folderId"
        placeholder="Save to existing folder"
        :options="artilcesFolders"
        :disabled="isSelectDisabled || activePortfolio"
        style="width: 100%"
      >
      </a-select>
      <a-input
        v-if="isNewFolder"
        type="text"
        v-model:value="folderName"
        placeholder="Enter folder name"
        class="input"
        :disabled="activePortfolio"
      >
      </a-input>
      <p class="error" v-if="fieldErr">This field can not be empty</p>
      <div class="toggle">
        <a @click="toggleFolderSelection">
          {{ titleText }}
        </a>
      </div>
      <a-radio-group name="radioGroup" v-model:value="radioValue">
        <a-radio value="portfolio"
          >Save to Content Management
          <p>
            Saves this article to your profile content management under saved articles.
          </p></a-radio
        >
        <a-radio value="folders"
          >Save to Folders
          <p>
            Saves to folders of your choice either personal or class folders.
          </p>
        </a-radio>
      </a-radio-group>
      <a-select
        v-model:value="selectedType"
        style="width: 100%"
        placeholder="Select folder type"
        :options="folderType"
        :disabled="!activePortfolio"
      >
      </a-select>
      <a-tree-select
        v-model:value="selectedFolder"
        style="width: 100%"
        :disabled="!activePortfolio"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :tree-data="folderList"
        placeholder="Please select"
        :treeIcon="true"
        tree-default-expand-all
      >
      </a-tree-select>
      <p class="error" v-if="selectedFolderErr">This field can not be empty</p>
    </div>
    <a-button :loading="loader" @click="saveArticle(isNewFolder)" >Save</a-button>
  </div>
</template>

<script>
export default {
  props: ["visible", "articleId"],
  emits: ["handle-close"],
  data() {
    return {
      radioValue: "portfolio",
      title: "Save",
      folderName: undefined,
      folderId: undefined,
      loader: false,
      fieldErr: false,
      isNewFolder: false,
      titleText: "Create new folder",
      selectedFolder: null,
      activePortfolio: false,
      selectedType: null,
      isTreeLoading: true,
      selectedFolderErr: false,
    };
  },
  computed: {
    isSelectDisabled() {
      return this.isNewFolder;
    },
    artilcesFolders() {
      const folders = this.$store.getters["article/getArticlesFolders"];
      return folders.map((val) => {
        let foldersList = {
          label: val.folder_name,
          value: val.id,
        };
        return foldersList;
      });
    },
    isInstitute() {
      return this.$store.getters["profile/userInformation"].profile.institute;
    },
    folderType() {
      const value = [
      {
        label: 'Class Folder',
        value: 'activeClassFolder',
      },
      {
        label: 'Personal Folder',
        value: 'activePersonalFolder',
      }
      ]
      return this.isInstitute?.permission === 'admin'
        || this.isInstitute?.permission === 'faculty'
        ? value : [{ ...value[1] }];
    },
    folderList() {
        const folders = this.$store.getters["folders/getFolderList"].map(folder => {
          const newFolder = {
            title: folder.name,
            key: folder.id,
            value: folder.id,
            children: folder.child ? folder.child.map(child => {
              const children = {
                title: child.name,
                key: folder.id,
                value: child.id,
              }
              return children;
            }) : []
          }
          return newFolder;
        });
        return folders
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.getSavedArticle();
      }
    },
    radioValue(val) {
      if (val === 'portfolio') {
        this.activePortfolio = false;
      } else {
        this.activePortfolio = true;
      }
    },
    selectedType(val) {
      this.getFolderTree(val);
    }
  },
  methods: {
    async getFolderTree(tab) {
      try {
        this.isTreeLoading = true;
        let classFolderTree = 'folders/getClassFolderTree';
        let personalFolderTree = 'folders/getPersonalFolderTree'
        let apiCall = tab === 'activeClassFolder' ? classFolderTree : personalFolderTree;
        const response = await this.$store.dispatch(apiCall);
        if (response.status === 200) {
          this.isTreeLoading = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    toggleFolderSelection() {
      this.isNewFolder = !this.isNewFolder;
      this.fieldErr = false;
      this.folderName = undefined;
      this.folderId = undefined;
      if (this.isNewFolder) {
        this.titleText = "Save to existing folder";
      } else {
        this.titleText = "Create new folder";
      }
    },
    async getSavedArticle() {
      try {
        const payload={
          user_id:this.$route.params.id,
        }
        await this.$store.dispatch("article/fetchSaveArticleFolder",payload);
      } catch (err) {
        console.log(err);
      }
    },
    // async saveArticle(newFolder) {
    //   try {
    //     this.loader = true;
    //     if (this.radioValue === "portfolio") {
    //       const key = newFolder ? "folder_name" : "folder_id";
    //       const value = newFolder ? this.folderName.trim() : this.folderId;
    //       if (!value) {
    //         this.fieldErr = true;
    //         return false;
    //       }
    //       const payload = {
    //         // If the folder is new then key will be folder_name but if folder is existing then key will be folder_id
    //         [key]: value,
    //         article_id: this.articleId,
    //       };

    //       const response = await this.$store.dispatch(
    //         newFolder
    //           ? "article/saveArticleFolder"
    //           : "article/updateArticleFolder",
    //         payload
    //       );

    //       if (response.status === 201 || response.status === 200) {
    //         this.fieldErr = false;
    //         this.folderName = undefined;
    //         this.folderId = undefined;
    //         const msg = {
    //           modalVisibility: false,
    //           showMessageModal: true,
    //           title: 'Article saved successfully',
    //           type: 'success'
    //         }
    //         this.emitter.emit("close-article-save-modal", msg);
    //       }
    //     } else {
    //       if (!this.selectedFolder) {
    //         this.selectedFolderErr = true;
    //         return false;
    //       }
    //       const form = new FormData();
    //       form.append("article", this.articleId);
    //       form.append("content", 'article');
    //       const payload = {
    //         folder_id: this.selectedFolder,
    //         article: form,
    //       }
    //       const response = await this.$store.dispatch("folders/saveContentInFolder", payload);
    //       if (response.status === 201) {
    //         this.selectedFolderErr = false;
    //         const msg = {
    //           modalVisibility: false,
    //           showMessageModal: true,
    //           title: 'Article saved successfully',
    //           type: 'success'
    //         }
    //         this.emitter.emit("close-article-save-modal", msg);
    //       }
    //     }
    //   } catch (err) {
    //     console.log(err);
    //     const msg = {
    //       modalVisibility: false,
    //       showMessageModal: true,
    //       title: err.response.data[0],
    //       type: 'failure'
    //     }
    //     this.emitter.emit("close-article-save-modal", msg);
    //   } finally {
    //     this.loader = false;
    //   }
    // },
    async saveArticle(newFolder) {
      try {
        this.loader = true;
        const { folderName, folderId, articleId, selectedFolder, radioValue } = this;
        if (radioValue === "portfolio") {
          let key, value;
          if (newFolder) {
            const folderNameTrimmed = folderName?.trim() || "";
            if (!folderNameTrimmed) {
              this.fieldErr = true;
              return;
            }

            key = "folder_name";
            value = folderNameTrimmed;

          } else {
            if (!folderId) {
              this.fieldErr = true;
              return;
            }

            key = "folder_id";
            value = folderId;
          }

          const payload = {
            [key]: value,
            article_id: articleId,
          };
          const response = await this.$store.dispatch(
            newFolder ? "article/saveArticleFolder" : "article/updateArticleFolder",
            payload
          );

          if ([200, 201].includes(response.status)) {
            this.fieldErr = false;
            this.folderName = this.folderId = undefined;

            this.emitter.emit("close-article-save-modal", {
              modalVisibility: false,
              showMessageModal: true,
              title: 'Article saved successfully',
              type: 'success'
            });
          }

        } else {
          if (!selectedFolder) {
            this.selectedFolderErr = true;
            return;
          }

          const form = new FormData();
          form.append("article", articleId);
          form.append("content", 'article');

          const payload = {
            folder_id: selectedFolder,
            article: form,
          };

          const response = await this.$store.dispatch("folders/saveContentInFolder", payload);

          if (response.status === 201) {
            this.selectedFolderErr = false;
            this.emitter.emit("close-article-save-modal", {
              modalVisibility: false,
              showMessageModal: true,
              title: 'Article saved successfully',
              type: 'success'
            });
          }
        }
      } catch (err) {
        this.emitter.emit("close-article-save-modal", {
          modalVisibility: false,
          showMessageModal: true,
          title: err.response?.data?.[0] || 'An error occurred',
          type: 'failure'
        });
      } finally {
        this.loader = false;
      }
    }

  },
};
</script>

<style lang="scss">
.save-article-modal {
  .ant-modal {
    width: 50.6rem !important;
    top: 8rem !important;
  }
  .ant-modal-header {
    padding: 0;
    border: none;
    margin-bottom: 1rem;
  }
  .ant-modal-content {
    padding: 2.2rem 2.5rem;
    border-radius: 0;
    position: relative;
    background-color: #f6f8ff;
    .ant-modal-close {
      margin-right: 2.2rem;
      margin-top: 2.5rem;
      height: fit-content;
      width: fit-content;
      .ant-modal-close-x {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        margin-right: 0;
        margin-top: 0;
        .anticon-close {
          svg {
            width: 2rem;
            color: $color-black;
          }
        }
      }
    }
    .ant-modal-body {
      .save-article-container {
        &__title {
          font-size: 2rem;
          font-family: $font-secondary-bold;
          line-height: normal;
          margin-bottom: 2.8rem;
          color: $color-black;
        }
        &__input-field {
          .input {
            background-color: $color-white;
            border: 1px solid #00000033;
            outline: none;
            height: 4rem;
            font-size: 1.4rem;
            font-family: $font-primary;
            color: $color-black;
            padding: 0 1rem;
            width: 100%;
            margin-bottom: 1.5rem;
            &::placeholder {
              opacity: 0.5;
              color: $color-black;
            }
          }
          .ant-radio-group {
            display: flex;
            flex-direction: column;
            .ant-radio-wrapper {
              margin-right: 0;
              font-size: 1.4rem;
              color: $color-black;
              font-family: $font-primary-medium;
              margin-bottom: 2rem;
              display: flex;
              opacity: 0.5;
              .ant-radio {
                display: inherit;
                .ant-radio-inner {
                  border: 0.2rem solid $color-black;
                  height: 2rem;
                  width: 2rem;
                  &::after {
                    background-color: $color-primary;
                    width: 1rem;
                    height: 1rem;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
              .ant-radio-checked {
                .ant-radio-inner {
                  border: 0.2rem solid $color-black;
                  &::after {
                    background-color: $color-primary;
                  }
                }
              }
              span {
                height: fit-content;
                display: block;
                p {
                  font-size: 1.2rem;
                  margin-top: 0.4rem;
                  margin-bottom: 0;
                  color: #646464;
                  line-height: 1.4rem;
                  font-family: $font-primary;
                }
              }
            }
            .ant-radio-wrapper-checked {
              opacity: 1;
            }
          }
          .ant-select {
            margin-bottom: 1.5rem;
            .ant-select-selector {
              border: 1px solid #00000033;
              background-color: $color-white;
              outline: none;
              height: 4rem;
              box-shadow: none;
              margin-bottom: 0;
              border-radius: 0.5rem !important;
              padding: 0 1rem;
              font-size: 1.4rem;
              font-family: $font-primary;
              color: $color-black;
              .ant-select-selection-search {
                .ant-select-selection-search-input {
                  height: 100%;
                }
              }
              .ant-select-selection-placeholder {
                opacity: 0.5;
                color: $color-black;
              }
              .ant-select-selection-item {
                font-size: 1.4rem;
                font-family: $font-primary;
                color: $color-black;
              }
            }
            .ant-select-arrow {
              top: 50%;
              transform: translateY(0%);
              .anticon {
                svg {
                  width: 1.5rem;
                  color: $color-black;
                }
              }
            }
          }
          .toggle {
            margin-top: -0.5rem;
            margin-bottom: 1.5rem;
            line-height: 1.8rem;
            a {
              color: $color-primary;
              font-size: 1.4rem;
              font-family: $font-primary;
            }
          }
        }
        .ant-btn {
          width: 11.5rem;
          background-color: $color-primary;
          border: none;
          display: block;
          height: 3.6rem;
          font-size: 1.4rem;
          font-family: $font-primary-medium !important;
          color: $color-white;
          margin-top: 2.8rem;
          margin-left: auto;
          span {
            line-height: normal;
          }
        }
        

        // .error {
        //   font-size: 1.4rem;
        //   font-family: $font-primary;
        //   color: red;
        //   margin-top: 0.5rem;
        // }
        // &__container-first {
        //   .input {
        //     background-color: $color-white;
        //     border: 1px solid rgba(1, 30, 41, 0.1);
        //     outline: none;
        //     height: 4rem;
        //     font-size: 1.6rem;
        //     font-family: $font-primary;
        //     color: $color-black;
        //     padding: 0 1rem;
        //     width: 100%;
        //     margin-top: 1.5rem;
        //     &::placeholder {
        //       opacity: 0.3;
        //     }
        //   }
        //   .toggle {
        //     font-size: 1.4rem;
        //     font-family: $font-primary;
        //     color: $color-primary;
        //     cursor: pointer;
        //     margin-top: 1rem;
        //   }
        //   .ant-select {
        //     width: 100%;
        //     .ant-select-selector {
        //       width: 100%;
        //       border: 1px solid rgba(1, 30, 41, 0.1);
        //       border-radius: 0.5rem !important;
        //       box-shadow: none;
        //       .ant-select-selection-item {
        //         font-size: 1.6rem;
        //         font-family: $font-primary;
        //       }
        //       .ant-select-selection-search {
        //         .ant-select-selection-search-input {
        //           height: 100%;
        //         }
        //       }
        //       .ant-select-selection-placeholder {
        //         font-size: 1.6rem;
        //         font-family: $font-primary;
        //       }
        //     }
        //   }
        //   .btn {
        //     margin-left: auto !important;
        //   }
        // }
        // &__container-second {
        //   .buttons-container {
        //     display: flex;
        //     align-items: center;
        //     justify-content: flex-end;
        //   }
        // }

       
      }
    }
  }
}
.ant-select-dropdown {
  .ant-select-tree {
    .ant-select-tree-treenode-switcher-open,
    .ant-select-tree-treenode-switcher-close {
      .ant-select-tree-node-content-wrapper {
        .ant-select-tree-iconEle {
          background-image: url("../../assets/icons/glocal-folder-icon.svg");
          height: 2rem;
          width: 2rem;
          margin-right: 1rem;
          background-size: contain; 
        }
        .ant-select-tree-title {
          font-size: 1.4rem;
          font-family: $font-primary;
          color: $color-black;
        }
      }
    }
  }
}
</style>

